import React from 'react';
import { PolicyPage } from './policy';
import { Home } from './home';
import { Routes, Route, Outlet, Link } from "react-router-dom";

function App() {
  return (
    <Routes>
    <Route path="/" element={<Home />} />
    <Route path='/policy' element={<PolicyPage />} />
  </Routes>
  );
}

export default App;
