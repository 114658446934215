import logo from './logo.png';
import './App.css';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { useState, useCallback } from 'react';

const HOST_NAME = "search-smart.com"

export function Home() {

  const [searchValue, setSearchValue] = useState("");
  const [suggestedKWs, setSuggestedKWs] = useState([]);

  

  function searchTerm(newValue) {
    const searchVal = newValue || searchValue;
    if (searchVal) {
      window.location.href = `https://api.${HOST_NAME}/search?s=smartSearch&q=${searchVal}`;
    }
  }

  function handleConfirm(e) {
    if (e.key === "Enter") {
      setTimeout(() => {
        searchTerm();
      }, 2000);
    }
  }

  const debouncedGetSuggestions = useCallback(getDebounceFunc((kw, setter) => {
    try {
      window.fetch(`https://api.${HOST_NAME}/suggested?q=${kw}`).then(response =>{
        if (response.ok) {
          response.json().then(parsedBody => {
            if (parsedBody?.suggested) {
              setter(parsedBody.suggested);
            }
          }).catch(e => console.log(e));
        }
      });
    } catch(e) {
      console.log(e);
    }
  }, 500), []);

  function textBoxChange(newValue, isFinal) {
    if (isFinal) {
      searchTerm(newValue);
    } else {
      if (newValue && newValue !== searchValue) {
        debouncedGetSuggestions(newValue, setSuggestedKWs);
      }
      setSearchValue(newValue);
    }
  }

  // make call to db to get auto complete words with debounse

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p className='title'>
          Smart Search
        </p>
        <Autocomplete
        id="free-solo-demo"
        freeSolo
        sx={{ width: 800 }}
        options={suggestedKWs}
        value={searchValue}
        onChange={(event, newVal, type) => type === 'selectOption' && textBoxChange(newVal, true)}
        renderInput={(params) => <TextField {...params} 
          onChange={(e) => textBoxChange(e.target.value)} 
          label="Search"
          onKeyDown={handleConfirm} />}
      />
      <Button className='searchButton' variant="outlined" onClick={() => searchTerm()}>Search</Button>
      </header>
    </div>
  );
}

const getDebounceFunc = (callback, delay) => {
    let timeout;
    return ((...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        callback(...args);
      }, delay);
    });
};

